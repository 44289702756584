.w-20 {
  width: 20% !important;
}

.w-40 {
  width: 40% !important;
}

.w-80 {
  width: 80% !important;
}

.w-600-px {
  width: 600px !important;
}

.icon-responsive {
  font-size: max(16px, min(2vw, 24px)) !important;
  width: max(16px, min(2vw, 24px)) !important;
  min-width: max(16px, min(2vw, 24px)) !important;
  height: max(16px, min(2vw, 24px)) !important;
  min-height: max(16px, min(2vw, 24px)) !important;
}

.mat-mdc-outlined-button.mat-mdc-button-base {
  padding: 0px 18px;
}

.mat-form-field-appearance-outline.mat-form-field-disabled {
  --mdc-outlined-text-field-disabled-outline-color: rgb(0 0 0 / 12%) !important;
}

.c-pointer {
  cursor: pointer;
}

.mat-field-hide-hint {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.text-hover-underline {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
  color: var(--secondary) !important;
}

.table-container {
  overflow-y: auto;
  
  .table {
    // border-spacing: 0px;
    border-collapse: collapse;
    border: 1px solid;

    th, td {
      padding: 4px 8px;
      border: 1px solid;  
      text-align: left;
    }
  }
}

.outline-0 {
  outline: 0 !important;
}

.z-1 {
  z-index: 1;
}