.bg-body {
  background-color: var(--body-bg) !important;
}

.bg-white {
  background-color: white !important;
}

// .bg-secondary {
//   background-color: #a5a49f !important;
// }

// .bg-primary {
//   background-color: var(--primary) !important;
// }

.bg-accent {
  background-color: var(--accent) !important;
  color: white !important;
}

.bg-blue {
  background-color: var(--blue) !important;
  color: white !important;
}

.bg-orange {
  background-color: var(--orange) !important;
  color: white !important;
}

// .bg-warn {
//   background-color: var(--warn) !important;
//   color: white !important;
// }

// .bg-success {
//   background-color: var(--success) !important;
//   color: white !important;
// }

// .bg-danger {
//   background-color: var(--danger) !important;
//   color: white !important;
// }

// .bg-warning {
//   background-color: var(--warning) !important;
//   color: white !important;
// }

// .bg-info {
//   background-color: var(--info) !important;
// }

// .bg-transparent {
//   background-color: transparent !important;
// }

.bg-secondary,
.bg-primary,
.bg-accent,
.bg-warn,
.bg-success,
.bg-danger,
.bg-warning,
.bg-info,
.bg-blue,
.bg-orange {
  color: white !important;

  &:disabled {
    color: rgba(255, 255, 255, 0.299) !important;
  }
}
