@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
@import 'assets/scss/theme.scss';
@import 'drawflow/dist/drawflow.min.css';

html,
body {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  --mat-toolbar-title-text-font: 'Poppins', sans-serif;
}
body {
  margin: 0;
  background-color: var(--body-bg);
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  height: 6px;    
  width: 6px;
}

*::-webkit-scrollbar-track {
  background-color: var(--body-bg);
  box-shadow: inset 0 0 2px grey;
}

*::-webkit-scrollbar-thumb {
  background-color: map-get($_primary, 60);
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: var(--primary);
  }
}

.scrollbar {
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
    // border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: map-get($_primary, 80);
    // border-radius: 4px;
    // border: 2px solid transparent;
    // background-clip: padding-box;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
    }
  }
}


#drawflow {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  background-size: 24px 24px;
  background-image: radial-gradient(rgba(133, 122, 122, 1) 1px, transparent 1px);
  border-radius: 0.25rem !important;
  border: 1px solid #dee2e6 !important;

  .drags {
    position: absolute;
    float: left;
    bottom: 12px;
    left: 12px;
    z-index: 5;

    .drag {
      cursor: move;
      user-select: none;

      .start-flow-icon {
        transform: rotateY(180deg);
      }
    }
  }

  .action-buttons {
    position: absolute;
    float: right;
    bottom: 12px;
    right: 12px;
    z-index: 5;
    
    .actions {      
      background: #000000ad;
      color: white;
    }
  }


  /* Editing Drawflow */
  .drawflow {
    .drawflow-node {
      background-color: white;
      border: 1px solid var(--border-color);
      box-shadow: 0 2px 15px 2px var(--border-color);
      padding: 0px;
      min-width: 160px;
  
      &.selected  {
        background: white;
        border: 1px solid var(--info);
        box-shadow: 0px 0px 1px 1px var(--info);
      }

      &.state {
        align-items: start;

        .inputs {
          top: 10px;
        }

        .outputs {
          top: auto;
          bottom: 30px;
        }
      }

      .inputs, .outputs {
        position: absolute;
        
        .input, .output {
          height: 12px;
          width: 12px;
          top: 0px;
          left: 0px;
          right: 0px;
          border-width: 2px;
          margin-bottom: 4px;
          border-style: solid;
          border-color: transparent;
          background-color: white;
          transition: ease-in-out 0.2s;
  
          &:hover {
            border: 0px;
            background-color: var(--info);
          }
        }
      }

      .inputs {
        left: -6px;

        .input {
          border-color: var(--success);
        }
      }

      .outputs {
        right: 6px;

        .output {
          border-color: var(--danger);
        }
      }

      select {
        width: 100%;
        border: 1px solid var(--border-color);
        padding: 2px;
        outline: none;
        font-size: 12px;
      }

      .open-edit-dialog-btn {
        &:hover {
          background-color: var(--primary) !important;
          color: white;
        }
      }
    }

    .connection {
      .main-path {
        stroke: var(--info);
        stroke-width: 2px;
      }

      .point {
        stroke: var(--border-color);
        stroke-width: 2;
        fill: white;      
        
        &.selected, &:hover {
          fill: var(--info);
        }
      }
    }
  }
  
  .drawflow-delete {
    border: 2px solid var(--danger);
    background: white;
    color: var(--danger);
    box-shadow: 0 2px 20px 2px var(--danger);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.point {
  position: relative;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;

  &::before, &::after{
    content: "";
    position: absolute;
    background-color: #ccc;
  }
}