
.applogin-banner {
  position: relative;
  margin-bottom: 10px;
  min-width: $container-min-width;

  .applogin-background {
    background-color: #fff;
    opacity: 0.9;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2px 1px rgba(175, 175, 175, 0.3);
  }

  .applogin-container {
    position: relative;
    width: $container-width;
    min-width: $container-min-width;
    margin: 0 auto;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;

    @include max-width-mq($container-width) {
      width: 100%;
    }

    @include max-height-mq(750px) {
      padding: 10px 0;
    }

    @include max-height-mq(660px) {
      padding: 5px 0;
    }

    h1 {
      font-size: 24px;
      font-weight: lighter;
      line-height: 26px;
    }

    p {
      @include max-height-mq(660px) {
        display: none;
      }
    }
  }

  .applogin-app-logo {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;

    img {
      max-height: 26px;
    }
  }
}
