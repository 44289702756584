// Generated from okta-core sha: 900f683
// Note: Do not modify this file directly.

$facebook-square: unicode('e077');
$facebook: unicode('e078');
$google-square: unicode('e079');
$google: unicode('e07f');
$twitter-square: unicode('e07c');
$twitter: unicode('e07b');
$linkedin-square: unicode('e07e');
$linkedin: unicode('e07d');
$openid: unicode('e07a');
